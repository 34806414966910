import React from 'react'

const NavList = ({classes ,close_click}) => {
  return (
   <>
     <div className={`header_div nav_link ${classes}`}>
          <ul className="nav_list">
            <li>
              <a onClick={close_click ? close_click : close_click} href="">Home</a>
            </li>
            <li>
              <a onClick={close_click ? close_click : close_click} href="">About</a>
            </li>
            <li>
              <a onClick={close_click ? close_click : close_click} href="">Tarrif</a>
            </li>
            <li>
              <a onClick={close_click ? close_click : close_click} href="">Contact</a>
            </li>
          </ul>
        </div>
        <div className={`header_div call_btn flex justify-end ${classes}`}>
          <a onClick={close_click ? close_click : close_click} href="tel:+917448844833">Call Now</a>
        </div>
   </>
  )
}

export default NavList
