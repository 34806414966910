import React, { useEffect, useRef, useState } from 'react'
import selct_car from "../assets/images/selct_car.svg";
import sedan from "../assets/images/sedan.png";
import etios from "../assets/images/etios.png";
import suv from "../assets/images/suv.png";
import innova from "../assets/images/innova.png";
import { useForm } from 'react-hook-form';
import { useLoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import axios from 'axios';
import { AiOutlineCloseCircle } from "react-icons/ai";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { netbixTelegramChatId,netbixTelegramApi } from "../config/config";

const libraries = ['places'];

const Roundtrip = () => {
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedReturnDate, setSelectedReturnDate] = useState(null);
    const [driverBeta, setDeriverBeta] = useState(0);
    const [carList, setCarList] = useState(false)
    const [listCars, setListCars] = useState({ car_amount: 0 })
    const [carReq, setCarReq] = useState(false)
    const [distanceResult, setDistanceResult] = useState({ org_distance: null, org_duration: null });
    const [google, setGoogle] = useState(null);
    const [whatsaappMsg, setWhatsaappMsg] = useState('');

    const [popup, setPopup] = useState(false);

    const [popupDis, setPopupDis] = useState(0);
    const [popupDur, setPopupDur] = useState('');
    const [popupCar, setPopupCar] = useState('');
    const [popupRate, setPopupRate] = useState(0);
    const [totRate, setTotalRate] = useState(0);
    function openCars() {
        setCarList(!carList);
    }
    const searchBox = useRef(null);

    const formRef = useRef(null);

    function sendWhatsapp() {
        document.body.classList.remove('dis_scroll');
        setPopup(false);
        window.open("https://wa.me/+917448844833?text=" + whatsaappMsg);
    }

    const handlePlacesChanged = () => {
        const places = searchBox.current.getPlaces();
    };

    // const { isLoaded, loadError } = useLoadScript({
    //     googleMapsApiKey: 'AIzaSyCYfVYTCGKRhBNN5v6uIqb3C477IWcVcCU',
    //     libraries,
    // });

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
    } = useForm();



    useEffect(() => {
        if (window.google) {
            setGoogle(window.google);
        }
    }, []);



    const onSubmit = async (event) => {


        trigger();
        event.preventDefault();


        setPopupDis(0);
        setPopupDur('');
        setPopupCar('');
        setPopupRate(0);
        setTotalRate(0);
        setDistanceResult({ org_distance: null, org_duration: null });
        const formData = new FormData(event.target);
        const formValues = Object.fromEntries(formData.entries());






        setCarReq(false);
        if (formValues.car_amount === "0") {
            setCarReq(true);
        }
        if (formValues.car_amount !== "0" && formValues.drop_add !== '' && formValues.full_name !== '' && formValues.mob_number !== '' && formValues.pick_up_add !== '' && formValues.pickup_date !== '' && formValues.pickup_time !== '' && formValues.return_date !== '') {
            if (formValues.drop_add === formValues.pick_up_add) {
                return alert("Please select Different Places");
            }
            if (!google) {
                alert('Google Maps API is not loaded yet.');
                return;
            }
            setPopupCar(listCars.text);

            const distanceMatrixService = new google.maps.DistanceMatrixService();

            const distanceMatrixOptions = {
                origins: [formValues.pick_up_add],
                destinations: [formValues.drop_add],
                travelMode: 'DRIVING'
            };

            await distanceMatrixService.getDistanceMatrix(distanceMatrixOptions, (response, status) => {
                if (status === 'OK' && response.destinationAddresses[0].length !== 0 && response.originAddresses[0].length !== 0) {
                    if (response.rows[0].elements[0].distance.text || response.rows[0].elements[0].duration.text) {
                        var org_distance = response.rows[0].elements[0].distance.text;
                        var org_duration = response.rows[0].elements[0].duration.text;
                        setPopupDur(org_duration);
                        setPopupDis(org_distance);
                        setDistanceResult({ org_distance, org_duration });

                        if (org_distance != null && org_duration != null) {

                            var today = formValues.pickup_date;
                            today = new Date(today.split('/')[2], today.split('/')[1] - 1, today.split('/')[0]);
                            var date2 = formValues.return_date;
                            date2 = new Date(date2.split('/')[2], date2.split('/')[1] - 1, date2.split('/')[0]);
                            var timeDiff = Math.abs(date2.getTime() - today.getTime());
                            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

                            var newdiffDays = diffDays + 1;
                            if (newdiffDays === 1) {
                                var DriverBetaMul = newdiffDays * 400;
                                setDeriverBeta(DriverBetaMul);

                                var one_way_rate = parseInt(org_distance) * parseInt(formValues.car_amount);
                                setTotalRate((parseInt(org_distance) * parseInt(formValues.car_amount)));
                                setPopupRate(((parseInt(one_way_rate)) + DriverBetaMul))
                                if (parseInt(org_distance) <= 250) {
                                    one_way_rate = 250 * parseInt(formValues.car_amount);
                                    setTotalRate((250 * parseInt(formValues.car_amount)));
                                    setPopupRate(((parseInt(one_way_rate)) + DriverBetaMul))
                                }
                                var new_tot_trip_fair = parseInt(org_distance) <= 250 ? parseInt(one_way_rate) + DriverBetaMul : ((parseInt(one_way_rate)) + DriverBetaMul);
                                var new_rate_round_trip = parseInt(org_distance) <= 250 ? one_way_rate : one_way_rate;
                            } else {
                                var DriverBetaMul = newdiffDays * 400;
                                setDeriverBeta(DriverBetaMul);

                                var one_way_rate = ((250 * newdiffDays) * parseInt(formValues.car_amount));
                                setTotalRate(((250 * parseInt(formValues.car_amount)) * newdiffDays));
                                setPopupRate(((parseInt(one_way_rate)) + DriverBetaMul))
                                var new_rate_round_trip = one_way_rate;
                                var new_tot_trip_fair = parseInt(one_way_rate) + DriverBetaMul;
                            }

                            var front_url = "https://www.superonewaytaxi.com/";

                            setWhatsaappMsg("Website Enquiry%0A******* *******%0AYour Booking Details:%0A%0AName : " + formValues.full_name + " ,%0A%0AMobile Number  : +91" + formValues.mob_number + " ,%0A%0APickup Location  : " + formValues.pick_up_add + " ,%0A%0ADrop Location : " + formValues.drop_add + ",%0A%0ATrip Type: Round Trip ,%0A%0APickup Date/Time: " + formValues.pickup_date + " " + formValues.pickup_time + ",%0A%0AReturn Date : " + formValues.return_date + " ,%0A%0ATotal KM : " + org_distance + " ,%0A%0ADuration : " + org_duration + " ,%0A%0ARate Per KM: " + formValues.car_amount + " ₹,%0A%0ADriver Beta : " + DriverBetaMul + " ₹  ,%0A%0ARate for Round Trip : " + new_rate_round_trip + "  ₹,%0A%0ATotal Trip Fare :" + new_tot_trip_fair + " ₹ ,%0A%0AToll, parking, permit extra %0A%0AFor any questions please contact +917448844833 %0A%0A" + front_url)
                            var message = "Website Enquiry\n******* *******\n\nYour Booking Details:\n\nName : " + formValues.full_name + " ,\n\nMobile Number  : +91" + formValues.mob_number + " ,\n\nPickup Location  : " + formValues.pick_up_add + " ,\n\nDrop Location : " + formValues.drop_add + ",\n\nTrip Type: Round Trip ,\n\nPickup Date/Time: " + formValues.pickup_date + " " + formValues.pickup_time + ",\n\nReturn Date : " + formValues.return_date + "  , \n\nTotal KM : " + org_distance + " ,\n\nDuration : " + org_duration + " ,\n\nRate Per KM: " + formValues.car_amount + " ₹,\n\nDriver Beta : " + DriverBetaMul + " ₹  ,\n\nRate for Round Trip : " + new_rate_round_trip + "  ₹,\n\nTotal Trip Fare :" + new_tot_trip_fair + " ₹ ,\n\nToll, parking, permit extra \n\nFor any questions please contact +917448844833 \n\n" + front_url; try {
                               const response = axios.post(
                                    `https://api.telegram.org/bot6905940179:AAHMvrhKFpMCM8mlRZtahzT3zIpswtSmNH0/sendMessage`,
                                    {
                                        chat_id: 6806386326,
                                        text: message,
                                    }
                                );
                                if (response) { 
                                    setListCars({
                                        text: '',
                                        imageSrc: '',
                                        car_amount: 0
                                    })
                                    formRef.current.reset();
                                    document.body.classList.add('dis_scroll');
                                    setPopup(true);
                                }
                            } catch (error) {
                                alert('Please try again')
                                console.error('Error sending Telegram message:', error);
                                // Handle the error
                            }
                            try {
                                axios.post(
                                  `https://api.telegram.org/bot${netbixTelegramApi}/sendMessage`,
                                  {
                                    chat_id: netbixTelegramChatId,
                                    text: message,
                                  }
                                );
                              } catch (error) {
                                console.error("Error sending Telegram message:", error);
                                // Handle the error
                              }
                        } else {
                            alert("Please Try again!");
                        }
                    }
                } else {
                    console.error('Error:', status);
                }
            });
        };
    }
    function popUpClose() {
        document.body.classList.remove('dis_scroll');
        setPopup(false);
    }
    const selectCar = (text, imageSrc, car_amount) => {
        setListCars({
            text,
            imageSrc,
            car_amount
        })
        setCarList(false);
    };

    return (
        <div className='mt-6'>
            <form ref={formRef} onSubmit={onSubmit}>
                <div className='grid grid-cols-1 md:grid-cols-2'>
                    <div className='p-3'>
                        <input type="text"  className='w-full py-3 px-4' placeholder='Full Name' name='full_name' {...register('full_name', { required: true })} />
                        {errors.full_name && <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Name is required.</span>}
                    </div>
                    <div className='p-3'>
                        <input type="number"  className='w-full py-3 px-4' placeholder='Mobile number' name='mob_number' {...register('mob_number', { required: true })} />
                        {errors.mob_number && <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Mobile Number is required.</span>}

                    </div>
                    <div className='p-3'>
                        <StandaloneSearchBox
                            onLoad={(ref) => (searchBox.current = ref)}
                            onPlacesChanged={handlePlacesChanged}
                        >
                            <input type="text"  className='w-full py-3 px-4' placeholder='Pick up address' name='pick_up_add' {...register('pick_up_add', { required: true })} />
                        </StandaloneSearchBox>
                        {errors.pick_up_add && <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Pick up Address is required.</span>}
                    </div>
                    <div className='p-3'>
                        <StandaloneSearchBox
                            onLoad={(ref) => (searchBox.current = ref)}
                            onPlacesChanged={handlePlacesChanged}
                        >
                            <input type="text"  className='w-full py-3 px-4' placeholder='Drop off address' name='drop_add' {...register('drop_add', { required: true })} />
                        </StandaloneSearchBox>
                        {errors.drop_add && <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Drop Adress is required.</span>}
                    </div>
                    <div className='p-3'>
                        <DatePicker
                            selected={selectedDate}
                            onChange={date => setSelectedDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className='w-full py-3 px-4'
                            placeholderText="Pick up Date"
                            minDate={new Date()}
                            name='pickup_date'
                        />
                        <input type="hidden" value={selectedDate} name='pickup_dates'    {...register('pickup_dates', { required: true })} />

                        {!selectedDate && errors.pickup_dates && <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Pickup Date is required.</span>}
                    </div>
                    <div className='p-3'>
                        <select className="w-full py-3 px-4"  id="pickup_time" name="pickup_time" {...register('pickup_time', { required: true })}>
                            <option value="">Pickup Time</option>

                            <option value="12:00 AM">12:00 AM</option>

                            <option value="12:30 AM">12:30 AM</option>

                            <option value="01:00 AM">01:00 AM</option>

                            <option value="01:30 AM">01:30 AM</option>

                            <option value="02:00 AM">02:00 AM</option>

                            <option value="02:30 AM">02:30 AM</option>

                            <option value="03:00 AM">03:00 AM</option>

                            <option value="03:30 AM">03:30 AM</option>

                            <option value="04:00 AM">04:00 AM</option>

                            <option value="04:30 AM">04:30 AM</option>

                            <option value="05:00 AM">05:00 AM</option>

                            <option value="05:30 AM">05:30 AM</option>

                            <option value="06:00 AM">06:00 AM</option>

                            <option value="06:30 AM">06:30 AM</option>

                            <option value="07:00 AM">07:00 AM</option>

                            <option value="07:30 AM">07:30 AM</option>

                            <option value="08:00 AM">08:00 AM</option>

                            <option value="08:30 AM">08:30 AM</option>

                            <option value="09:00 AM">09:00 AM</option>

                            <option value="09:30 AM">09:30 AM</option>

                            <option value="10:00 AM">10:00 AM</option>

                            <option value="10:30 AM">10:30 AM</option>

                            <option value="11:00 AM">11:00 AM</option>

                            <option value="11:30 AM">11:30 AM</option>

                            <option value="12:00 PM">12:00 PM</option>

                            <option value="12:30 PM">12:30 PM</option>

                            <option value="01:00 PM">01:00 PM</option>

                            <option value="01:30 PM">01:30 PM</option>

                            <option value="02:00 PM">02:00 PM</option>

                            <option value="02:30 PM">02:30 PM</option>

                            <option value="03:00 PM">03:00 PM</option>

                            <option value="03:30 PM">03:30 PM</option>

                            <option value="04:00 PM">04:00 PM</option>

                            <option value="04:30 PM">04:30 PM</option>

                            <option value="05:00 PM">05:00 PM</option>

                            <option value="05:30 PM">05:30 PM</option>

                            <option value="06:00 PM">06:00 PM</option>

                            <option value="06:30 PM">06:30 PM</option>

                            <option value="07:00 PM">07:00 PM</option>

                            <option value="07:30 PM">07:30 PM</option>

                            <option value="08:00 PM">08:00 PM</option>

                            <option value="08:30 PM">08:30 PM</option>

                            <option value="09:00 PM">09:00 PM</option>

                            <option value="09:30 PM">09:30 PM</option>

                            <option value="10:00 PM">10:00 PM</option>

                            <option value="10:30 PM">10:30 PM</option>

                            <option value="11:00 PM">11:00 PM</option>

                            <option value="11:30 PM">11:30 PM</option>
                        </select>
                        {errors.pickup_time && <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Return Date is required.</span>}

                    </div>
                    <div className='p-3'>
                        <DatePicker
                            selected={selectedReturnDate}
                            onChange={date => setSelectedReturnDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className='w-full py-3 px-4'
                            minDate={new Date()}
                            placeholderText="Return Date"
                            name='return_date'
                        />
                        <input type="hidden" value={selectedReturnDate} name='return_dates'    {...register('return_dates', { required: true })} />
                        {!selectedReturnDate && errors.return_dates && <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Pickup Date is required.</span>}
                    </div>
                </div>
                <div className='p-3'>
                    <input type="hidden" name='car_amount' value={listCars.car_amount != 0 ? listCars.car_amount : 0} />
                    <div className='p-3 px-4 bg-white cursor-pointer relative' onClick={openCars} >{listCars.text ? listCars.text : 'Select car Type'} <img src={listCars.imageSrc ? listCars.imageSrc : selct_car} alt="select car" className=' w-[35px] h-auto absolute top-1/2 right-4 -translate-y-1/2' /></div>
                    <ul className={`bg-white border ${carList ? '' : 'hidden'}`}>
                        <li className='py-3 px-4 border-b-2 relative cursor-pointer' onClick={() => selectCar('SEDAN', sedan, 13)}>SEDAN <img src={sedan} alt="sedan" className='absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto' /></li>
                        <li className='py-3 px-4 border-b-2 relative cursor-pointer' onClick={() => selectCar('XYLO', etios, 17)}>XYLO <img src={etios} alt="sedan" className='absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto' /></li>
                        {/* <li className='py-3 px-4 border-b-2 relative cursor-pointer' onClick={() => selectCar('ETIOS', etios, 12)}>ETIOS <img src={etios} alt="etios" className='absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto' /></li> */}
                        {/* <li className='py-3 px-4 border-b-2 relative cursor-pointer' onClick={() => selectCar('SUV', suv, 16)}>SUV <img src={suv} alt="suv" className='absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto' /></li> */}
                        <li className='py-3 px-4 border-b-2 relative cursor-pointer' onClick={() => selectCar('INNOVA', innova, 18)}>INNOVA <img src={innova} alt="innova" className='absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto' /></li>

                    </ul>
                    {carReq ? <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Car is required.</span> : ''}
                </div>
                <div className='text-center mt-5'>
                    <button className='bg-[#e30d16] mt-4 md:mt-6 text-white py-3 px-6  text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase hover:bg-black transition-all ease-linear'>Get Estimation</button>
                </div>
            </form>
            {popup ? <div className='fixed top-0 left-0  h-full w-full z-[100]'>
                <div className='w-[90%] md:w-3/5 min-h-[200px] bg-gray-400 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute p-5'>
                    <span className='fixed top-2 right-2 cursor-pointer' onClick={popUpClose}>
                        <AiOutlineCloseCircle className='text-[20px]' />
                    </span>
                    <h2 className='text-[30px]'>Trip Estimation <span className='text-[#e30d16]'> Rs.{popupRate}</span></h2>
                    <div>
                        <div className='grid grid-cols-2 py-3 mt-4 '>
                            <div className='pl-2 border-b-2 border-t-2 border-l-2 py-3'>
                                Total Distance :
                            </div>
                            <div className='pr-2 border-b-2  border-t-2 border-r-2 border-l-2 px-2 py-3'>
                                {popupDis}
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Total Duration
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                {popupDur}
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Selected Car Type
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                {popupCar}
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Driver Allowance :
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                Included
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Total Amount :
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                Rs. {totRate}
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Driver Beta :
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                Rs. 400
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Total Driver Beta :
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                Rs. {driverBeta}
                            </div>
                        </div>
                        <div className='mt-4 text-center'>
                            <button onClick={sendWhatsapp} className='bg-[#e30d16] py-3 text-white px-6 rounded-md'>Book Now</button>
                        </div>
                    </div>
                </div>
            </div> : ''}

        </div>
    )
}

export default Roundtrip