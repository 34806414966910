import React from 'react'

const RideComponents = ({ ride_img, model_name, car_name }) => {
    return (
        <div className='flex flex-col bg-[#1b1b1b] gap-y-8 justify-between overflow-hidden'>
            <img src={ride_img} alt="cars_models" className='w-[350px] h-auto mx-auto mt-4 hover:scale-[1.11] transition-all ease-linear'/>
            <div className='text-center'>
                <div className='bg-[#ffffff] inline-block w-[65%] py-3 rounded-t-[45px]'>
                    <h3 className='text-[20px] sm:text-[22px] md:text-[24px] font-semibold uppercase  '>{model_name}</h3>
                    <p className='text-[#e30d16] text-[14px] md:text-[16px] font-medium'>{car_name}</p>
                </div>
            </div>
        </div>
    )
}

export default RideComponents