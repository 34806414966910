import React from "react";
import icon from "../assets/images/Skygroups.jpg";
import { Link } from "react-router-dom";
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTwitter,
  IoLogoYoutube,
} from "react-icons/io5";
import "../footer.css";

const Footer = () => {
  return (
    <div className=" footer_parent">
      <div className=" footer_container ">
        <div className="flex flex-col md:flex-row text-center md:text-left gap-y-6 gap-x-3 border-b border-[#7b7e9929] pb-[30px]">
          <div className="footer_div w-full md:w-1/3">
            <h4 className="footer_title">About Us</h4>
            <p className="f_desc">
              Super Oneway Taxi offers low-cost one-way and round-trip
              transportation.
            </p>
          </div>
          <div className="footer_div w-full md:w-1/3">
            <h4 className="footer_title">Drop Taxi</h4>
            <ul className=" flex flex-col gap-y-4">
              <li>
                <Link>Chennai Drop Taxi</Link>
              </li>
              <li>
                <Link>Coimbatore Drop Taxi</Link>
              </li>
              <li>
                <Link>Madurai Drop Taxi</Link>
              </li>
              <li>
                <Link>Pondicherry Drop Taxi</Link>
              </li>
              <li>
                <Link>Bangalore Drop Taxi</Link>
              </li>
            </ul>
          </div>
          <div className="footer_div w-full md:w-1/3">
            <h4 className="footer_title">Taxi Sercice</h4>
            <ul className=" flex flex-col gap-y-4">
              <li>
                <Link>One Way Drop Taxi</Link>
              </li>
              <li>
                <Link>Round Trip Taxi</Link>
              </li>
              <li>
                <Link>Airport Taxi</Link>
              </li>
              <li>
                <Link>Hourly Rental Taxi </Link>
              </li>
            </ul>
          </div>
          <div className="footer_div w-full md:w-1/3">
            <h4 className="footer_title">Usefull Information</h4>
            <ul className=" flex flex-col gap-y-4">
              <li>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="pt-[1.5em]">
          <div className=" flex gap-x-3 flex-col gap-y-6 md:flex-row justify-between">
            <div className="">
              <p className=" text-[16px] text-[#7b7e99] text-center md:text-left">
                © 2022 Super Oneway Taxi. All rights reserved.
              </p>
            </div>
            <div className="">
              <p className=" text-[16px] text-[#7b7e99] text-center md:text-left">
                Developed by Netbix Technologies
              </p>  
              {/* <div className=" flex gap-x-2 justify-center f_social_media">
                <a href="#" className=" text-white text-xl">
                  <IoLogoFacebook />
                </a>
                <a href="#" className=" text-white text-xl">
                  <IoLogoTwitter />
                </a>
                <a href="#" className=" text-white text-xl">
                  <IoLogoInstagram />
                </a>
                <a href="#" className=" text-white text-lg">
                  <IoLogoYoutube />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
