import React, { useState } from "react";
import logo from "../assets/images/logo.png";
// import { AiOutlineMail } from "react-icons/ai";
// import { BsTelephone } from "react-icons/bs";
// import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillCloseCircle } from "react-icons/ai";
// import Lottie from "lottie-react";
// import animationData from "../assets/images/phoneicon.json";

import NavList from "./NavList";
import { Link } from "react-router-dom";
import "../header.css";
import { RxHamburgerMenu } from "react-icons/rx";

const Header = () => {
  const [openNav, setOpenNav] = useState(false);
  function openNavBat() {
    setOpenNav(true);
  }
  function closeNavBar() {
    setOpenNav(false);
  }
  return (
    <div className="header_wrap">
      <div className="header_container">
        <Link to="/" className="header_div">
          <img src={logo} alt="" className="w-[200px] md:w-[74%]" />
        </Link>
        <div className="block md:hidden cursor-pointer pr-8 ml-auto">
          <span onClick={openNavBat}>
            <RxHamburgerMenu className=" text-[24px] md:text-[28px]" />
          </span>
        </div>
        <NavList classes="hidden md:flex justify-center navlist" />
        <div
          className={`fixed bg-white h-full w-[60%] top-0 left-0 z-50 transition-all ease-linear ${
            openNav ? "" : "-translate-x-[110%]"
          }`}
        >
          <div className="px-4 py-3 bg-[#ffc9cb]">
            <img src={logo} alt="logo" className="w-[250px] mx-auto px-5" />
          </div>
          <div className="py-6 px-4 flex justify-start gap-y-5 flex-col items-start">
            <NavList
              classes="mobile_nav"
              close_click={closeNavBar}
            />
            <span
              onClick={closeNavBar}
              className="absolute top-4 -right-3 cursor-pointer"
            >
              <AiFillCloseCircle className="text-[25px] text-[#e30d16] " />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
