import React from "react";
import temp from "../assets/images/temp.jpg";
import Form from "./Form";

const Banner = () => {
  return (
    <div className="bg-gray-300" id="home">
      <div className="container mx-auto py-16 md:py-20 px-5">
        <div className="flex flex-col lg:flex-row justify-center items-center flex-wrap lg:gap-y-8 md:flex-nowrap gap-y-3  md:gap-y-0 gap-x-0  md:gap-x-3 lg:gap-x-5 xl:gap-x-8">
          <div className="sm:w-[100%] lg:w-[50%]">
            <h5 className="text-[#fcc233] bg-[#da1f1f] text-[20px] pl-1 font-semibold italic">Enjoy your ride!</h5>
            <h4 className="text-[28px] sm:text-[30px] md:text-[35px] font-bold text-[#26273c] mt-2">
              Book your ride with Super Oneway Taxi
            </h4>
            <p className="text-[16px] sm:text-[17px] md:text-[19px] text-[#7b7e99]">
              Super Oneway Taxi offers low-cost one-way and round-trip
              transportation. Super Oneway Taxi is a well-known cab service in
              Super that operates between several destinations throughout Tamil
              Nadu.
            </p>
            {/* <a
              href="#book_taxi_form"
              className="bg-[#e30d16]  mt-4 md:mt-6 text-white py-4 px-8  text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase hover:bg-black transition-all ease-linear"
            >
              Book a Taxi
            </a> */}
          </div>
          <div className="sm:w-[100%] lg:w-[50%]">
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
