import React, { Fragment, useEffect, useState } from "react";
import About from "./About";
import Banner from "./Banner";
import Footer from "./Footer";
import Header from "./Header";
import Look from "./Look";
import PhoneComponent from "./PhoneComponent";
import Price from "./Price";
import Ride from "./Ride";
import ScrollUp from "./ScrollUp";
import TopRoutes from "./TopRoutes";
import WhatsappComponent from "./WhatsappComponent";
import TermsandCondition from "./TermsandCondition";
import PrivacyPolicy from "./PrivacyPolicy";
import { animateScroll as scroll } from "react-scroll";

const Home = () => {
  const [changeLink, setChangeLink] = useState("home");
  // alert(changeLink);
  // useEffect(() => {
  //     if(changeLink){
  //         scroll.scrollToTop({ smooth: false });
  //     }
  // }, [changeLink])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Banner />
      <About />
      <Price />
      <Ride />
      <TopRoutes />
      <Look />
      {/* <WhatsappComponent />
      <PhoneComponent />
      <ScrollUp /> */}
      {/* <Fragment>
                    <TermsandCondition />
                    <WhatsappComponent />
                    <PhoneComponent />
                    <ScrollUp />
                </Fragment> */}
      {/* <Fragment>
                    <PrivacyPolicy />
                    <WhatsappComponent />
                    <PhoneComponent />
                    <ScrollUp />
                </Fragment> */}
    </Fragment>
  );
};

export default Home;
