import React from 'react'
import about from "../assets/images/aboutgif.gif";

const About = () => {
    return (
        <div className='container mx-auto py-10 md:py-16 px-5' id='about'>
            <div className=' flex items-center gap-y-4  md:gap-y-0  gap-x-0 sm:gap-x-0 md:gap-x-36 flex-wrap md:flex-nowrap'>
                <div className='sm:w-[100%]  md:w-[40%]'>
                    <img src={about} alt="about" />
                </div>
                <div className='w-full md:w-[60%]'>
                    <h5 className='text-[#e30d16] font-semibold'>ABOUT US</h5>
                    <h3 className='text-[#000] mt-4 font-bold text-[40px] sm:text-[45px] md:text-[50px] leading-[1]'>Taxi service with wide range of AC cabs</h3>
                    <p className='sm:mt-2 md:mt-4 w-full md:w-[80%]'>Super Oneway Taxi has a wide range of AC luxurious cabs that you can select for your comfortable. It is easy to book cabs with us anywhere in Tamil Nadu and enjoy your ride. </p>
                    <a href="#book_taxi_form" className='bg-[#e30d16] mt-6 text-white py-4 px-8 text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase skew-x-[-20deg] book_taxi'><span className='skew-x-[20deg] inline-block '>Book Now</span></a>
                </div>
            </div>
        </div>
    )
}

export default About