import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TermsandCondition from "./components/TermsandCondition";
import WhatsappComponent from "./components/WhatsappComponent";
import PhoneComponent from "./components/PhoneComponent";
import ScrollUp from "./components/ScrollUp";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermsandCondition />}
          ></Route>
        </Routes>
        <Footer />
        <WhatsappComponent />
        <PhoneComponent />
        <ScrollUp />
      </BrowserRouter>
    </div>
  );
}

export default App;
