import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import suvcar from "../assets/images/suvcaro.png";
import PriceCars from './PriceCars';
import primesuv from "../assets/images/prime_suvcaro.png";
import sedan from "../assets/images/sedancar.png";
import primesedan from "../assets/images/primesedancaro.png"
const Price = () => {

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2500 ,
    prevArrow: <></>, 
    nextArrow: <></> ,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className='bg-gray-300' id='our_pricing'>
      <div className='container mx-auto px-5 py-10 md:py-20'>
        <h5 className='text-[#e30d16] font-semibold text-center'>TAXI RATES</h5>
        <h3 className='text-[#000] mt-3 font-bold text-[40px] sm:text-[45px] md:text-[50px] text-center'>Our Pricing</h3>
        <div className='container mx-auto px-5 mt-10'>
          <Slider {...carouselSettings}>
            {/* <PriceCars img={suvcar} car_name="SUV" cat_trip="ROUND-TRIP" amount="Rs.16" /> */}
            <PriceCars img={sedan} car_name="SEDAN" cat_trip="ROUND-TRIP" amount="Rs.13" />
            <PriceCars img={suvcar} car_name="XYLO" cat_trip="ROUND-TRIP" amount="Rs.17" />
            <PriceCars img={primesuv} car_name="INNOVA" cat_trip="ONE-WAY" amount="Rs.20" />
            {/* <PriceCars img={primesedan} car_name="ETIOS" cat_trip="ROUND-TRIP" amount="Rs.12" /> */}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default Price