import React from 'react'
import RideComponents from './RideComponents'
import car1 from '../assets/images/ride1.png';
import car2 from '../assets/images/car2.jpg';
import car3 from '../assets/images/car3.png';

const Ride = () => {
    return (
        <div id='our_vehicles'>
            <div className='container mx-auto py-5 md:py-10 px-5'>
                <h5 className='text-[#e30d16] font-semibold text-center'>OUR VEHICLES</h5>
                <h3 className='text-[#000] mt-4 font-bold text-[37px] sm:text-[42px] md:text-[50px] text-center'>RIDE WITH EXPERTS</h3>
                <div className='grid sm:grid-cols-1 md:grid-cols-3  mt-4 sm:gap-x-0 md:gap-x-6 gap-y-4 sm:gap-y-4 md:gap-y-0'>
                    <RideComponents ride_img = {car1} model_name="Swift Dzire" car_name="SEDAN"/>
                    <RideComponents ride_img = {car2} model_name="Toyoto Innova" car_name="PRIME SUV"/>
                    <RideComponents ride_img = {car3} model_name="Mahendra Xylo" car_name="SUV"/>
                </div>
            </div>
        </div>
    )
}

export default Ride